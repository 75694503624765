<template>
  <div class="OnlineClassTeacher">
    
    <div class="stat-data" v-if="summary_data">
        <div class="stat-item">
            <div class="stat-text">
                <p class="stat-num">{{summary_data.Cost}}</p>
                <p class="stat-text-title">费用总计</p>
            </div>
        </div>
        <div class="stat-item">
            <div class="stat-text">
                <p class="stat-num">{{summary_data.attendance_data.attendance}}</p>
                <p class="stat-text-title">出勤人数(迟到 {{summary_data.attendance_data.late}} 早退 {{summary_data.attendance_data.leave_early}})</p>
            </div>
        </div>
        <div class="stat-item">
            <div class="stat-text">
                <p class="stat-num">{{summary_data.absence_count}}</p>
                <p class="stat-text-title">缺勤人数</p>
            </div>
        </div>
        <div class="stat-item">
            <div class="stat-text">
                <p class="stat-num">{{summary_data.ActualClassTime}}</p>
                <p class="stat-text-title">实际上课(小时)</p>
            </div>
        </div>
    </div>

    <div class="search-item">
        <a-tree-select
            style="width: 250px;margin-right: 10px;"
            v-model="OrgIds"
            :tree-data="treeData"
            tree-checkable
            :maxTagCount="1"
            placeholder="请选择搜索范围"
            @change="onOrgChange"
            :show-checked-strategy="SHOW_PARENT"
          />

        <a-input type="text" v-model="Keyword" placeholder="搜索老师名称" style="width: 150px;margin-right: 10px;"/>

        <a-select placeholder="是否跟踪" v-model="isRecord"  style="width: 150px;margin-right: 10px;">
            <a-select-option value="">全部</a-select-option>
            <a-select-option value="1">是</a-select-option>
            <a-select-option value="0">否</a-select-option>
        </a-select>

        <a-select placeholder="跟踪标签" v-model="RecordTags"  style="width: 150px;margin-right: 10px;">
            <a-select-option value="正常">正常</a-select-option>
            <a-select-option value="CPU异常">CPU异常</a-select-option>
            <a-select-option value="网络异常">网络异常</a-select-option>
            <a-select-option value="操作异常">操作异常</a-select-option>
            <a-select-option value="优秀">优秀</a-select-option>
            <a-select-option value="一般">一般</a-select-option>
        </a-select>

        <a-input type="text" v-model="MacId" placeholder="标识码" style="width: 150px;margin-right: 10px;"/>

        <a-range-picker :ranges="RangesConf" @change="onDateChange" style="margin-right: 10px;" :defaultValue="defaultDate"/>

        <a-button style="margin-right: 10px;" @click="reset">重 置</a-button>

        <a-button type="primary" @click="_search">搜 索</a-button>
        <a href="#" @click="JumpStudent" style="margin-top: 12px;margin-left: 10px;color: #1890ff;">查看学生相关表>>></a>

    </div>

	<div class="table-list">
        <a-table :columns="columns" :data-source="dataList" :pagination="false" @change="handleTableChange">
            <div v-if="record.ActualClassTime > 0" slot="EquipmentAndNet" slot-scope="text, record, index">
                <a href="#" style="color: #1890ff;" @click="_showOnlineClassDevice(record)">设备</a>&nbsp;&nbsp;
                <a-popover trigger="hover" placement="left" @visibleChange="_showEchart(record,'net',$event)">
                  <template slot="content">
                    <div :id="'echarts-data-net-'+record.Num" style="width:550px;height:450px"></div>
                  </template>
                  <a href="#" style="color: #1890ff;">网络</a>
                </a-popover>
                &nbsp;&nbsp;
                <a-popover  trigger="hover" placement="left" @visibleChange="_showEchart(record,'cpu',$event)">
                  <template slot="content">
                    <div :id="'echarts-data-cpu-'+record.Num" style="width:550px;height:450px"></div>
                  </template>
                  <a href="#" style="color: #1890ff;">CPU</a>
                </a-popover>
            </div>
            <div v-else slot="EquipmentAndNet" slot-scope="text, record, index" style="text-align: center;">
                --
            </div>
            <div slot="SeePlayBack" slot-scope="text, record, index">
                <a href="#" v-if="text" @click="SeePlayBack(text)" style="color: #1890ff;">播放</a>
            </div>
            <div slot="StudentNum" slot-scope="text, record, index">
                <a href="#" @click="_StudentList(record,1)" style="color: #1890ff;">{{text}}</a>
            </div>
            <div slot="AttendanceNum" slot-scope="text, record, index">
                <a href="#" @click="_StudentList(record,0)" style="color: #1890ff;">{{text}}</a>
            </div>
            <div slot="ClassInfo" slot-scope="text, record, index" style="font-size:10px">
                <p><b>上课时间:</b>{{record.ClassDate}}</p>
                <p><b>课程名称:</b>{{record.ClasstrackInfo.ClassName}}-{{record.ClasstrackInfo.ClassStage}}</p>
                <p><b>年龄段:</b>{{record.ClasstrackInfo.Age}}-{{record.ClasstrackInfo.Month}}-{{record.ClasstrackInfo.Week}}</p>
            </div>
            <div slot="remarks" slot-scope="text, record, index">
                <a href="#" style="color: #1890ff;" @click="show_remarks(record.ClasstrackId)">详情</a>
            </div>
        </a-table>
        <a-pagination v-model="currPage" v-if="DataCount > 10" style="margin-top: 18px;text-align: right;" @change="_changePage" :total="DataCount"  show-less-items />
    </div>

    <div class="shade" style="opacity: .8" @click="_onCloseVideo" v-if="shade"></div>
    <div v-show="CurrPlayUrl" class="seevideo">
        <p style="color: #fff;text-align: right;cursor: pointer;" @click="_onCloseVideo">关闭</p>
        <video :src="CurrPlayUrl" ref="video" controls="controls" controlsList="nodownload" style="width:700px" id="video1" ></video>
    </div>

    <a-drawer title="会员数据" placement="right" width="850" :closable="false" @close="onCloseShowStudent":visible="ShowStudentVisible">
        <OnlineClassStudent :queryData="queryData"></OnlineClassStudent>
    </a-drawer>

    <a-drawer title="网络信息" placement="right" width="1200" :closable="false" @close="onCloseOnlineClassNet":visible="OnlineClassNetVisible">
        <OnlineClassNet :OnlineClassNetData="OnlineClassNetData"></OnlineClassNet>
    </a-drawer>

    <a-drawer title="设备信息" placement="right" width="1200" :closable="false" @close="onCloseOnlineClassDevice":visible="OnlineClassDeviceVisible">
        <OnlineClassDevice :OnlineClassDeviceData="OnlineClassDeviceData"></OnlineClassDevice>
    </a-drawer>

    <a-drawer title="课后跟踪" placement="right" width="650" :closable="false" @close="onCloseRemarksVisible":visible="RemarksVisible">
        <div style="margin-bottom: 10px;">
            <span>类型：</span>
            <a-select placeholder="类型" v-model="Type" style="width: 200px;">
            <a-select-option value="">无</a-select-option>
            <a-select-option value="IT">IT</a-select-option>
            <a-select-option value="教学">教学</a-select-option>
        </a-select>
        </div>

        <div style="margin-bottom: 10px;">
            <span>标签：</span>
            <a-select placeholder="标签" v-model="Tags"  style="width: 200px;">
                <a-select-option value="">无</a-select-option>
                <a-select-option value="正常">正常</a-select-option>
                <a-select-option value="CPU异常">CPU异常</a-select-option>
                <a-select-option value="网络异常">网络异常</a-select-option>
                <a-select-option value="操作异常">操作异常</a-select-option>
                <a-select-option value="优秀">优秀</a-select-option>
                <a-select-option value="一般">一般</a-select-option>
            </a-select>
            <!-- <a-tag color="#52c41a">正常</a-tag>
            <a-tag color="#f5222d">异常</a-tag>
            <a-tag color="#52c41a">优秀</a-tag>
            <a-tag color="#faad14">一般</a-tag> -->
        </div>

        <div style="width:80%">
            <!-- <a-input type="text" style="width:80%;margin-right:10px;"/> -->
            <a-textarea placeholder="跟踪内容" v-model="Content"></a-textarea>
        </div>
        
        
        <a-button type="primary" style="margin-top: 10px;" @click="SaveRemarks">提交</a-button>

        <div class="remarks_list" v-if="RemarksList">
            <a-list item-layout="horizontal" :data-source="RemarksList">
                <a-list-item slot="renderItem" slot-scope="item, index">
                  <a-list-item-meta :description="item.description">
                    <a slot="title" href="#">{{ item.title }} 
                        <a-tag color="#52c41a" style="height: 20px;line-height: 19px;" v-if="item.tags=='正常'">正常</a-tag>
                        <a-tag color="#f5222d" style="height: 20px;line-height: 19px;" v-else-if="item.tags=='CPU异常'">CPU异常</a-tag>
                        <a-tag color="#f5222d" style="height: 20px;line-height: 19px;" v-else-if="item.tags=='网络异常'">网络异常</a-tag>
                        <a-tag color="#f5222d" style="height: 20px;line-height: 19px;" v-else-if="item.tags=='操作异常'">操作异常</a-tag>
                        <a-tag color="#52c41a" style="height: 20px;line-height: 19px;" v-else-if="item.tags=='优秀'">优秀</a-tag>
                        <a-tag color="#faad14" style="height: 20px;line-height: 19px;" v-else-if="item.tags=='一般'">一般</a-tag>
                    </a>
                  </a-list-item-meta>
                </a-list-item>
          </a-list>
        </div>
    </a-drawer>

  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import moment from 'moment'
import OnlineClassStudent from 'pages/ReportCenter/OnlineClassStudent'
import OnlineClassNet from 'pages/ReportCenter/OnlineClassNet'
import OnlineClassDevice from 'pages/ReportCenter/OnlineClassDevice'
import OnlineClassStudentList from 'pages/ReportCenter/OnlineClassStudentList'
import * as echarts from 'echarts';
export default {
	name: 'OnlineClassTeacher',
    components: { OnlineClassStudent, OnlineClassNet, OnlineClassDevice, echarts, OnlineClassStudentList },
    data () {
        return {
            userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
            dataList :[],
            RangesConf:{},
            SelectId:'',
            StartDate:undefined,
            EndDate:undefined,
            defaultDate:[moment().week(moment().week()).startOf('week'),moment()],
            currPage:1,
            DataCount:0,
            LabelName:'ClassSummary|Individual|',
            OrgIds:[],
            SelectDataList:[],
            func:'',
            columns:[
                {
                    dataIndex: 'Num',
                    key: 'Num',
                    title: '序号'
                },
                {
                    title: '老师',
                    dataIndex: 'TeacherName',
                    key: 'TeacherName',
                },
                {
                    title: '所属中心',
                    dataIndex: 'SchoolName',
                    key: 'SchoolName',
                },
                {
                    title: '课程信息',
                    dataIndex: 'ClassInfo',
                    key: 'ClassInfo',
                    scopedSlots: { customRender: 'ClassInfo' }
                },
                {
                    title: '费用',
                    dataIndex: 'Cost',
                    key: 'Cost',
                    sorter:true,
                },
                {
                    title: '出勤',
                    dataIndex: 'Attendance',
                    key: 'Attendance',
                },
                {
                    title: '迟到',
                    dataIndex: 'Late',
                    key: 'Late',
                },
                {
                    title: '早退',
                    dataIndex: 'LeaveEarly',
                    key: 'LeaveEarly',
                },
                {
                    title: '上课时长(分)',
                    dataIndex: 'ClassTime',
                    key: 'ClassTime',
                    sorter:true,
                },
                {
                    title: '学生人数',
                    dataIndex: 'StudentNum',
                    key: 'StudentNum',
                    scopedSlots: { customRender: 'StudentNum' },
                    sorter:true,
                },
                {
                    title: '出勤人数',
                    dataIndex: 'AttendanceNum',
                    key: 'AttendanceNum',
                    scopedSlots: { customRender: 'AttendanceNum' },
                    sorter:true,
                },
                {
                    title: '实际上课时长(分)',
                    dataIndex: 'ActualClassTime',
                    key: 'ActualClassTime',
                    sorter:true,
                    
                },
                {
                    title: '查看回放',
                    dataIndex: 'SeePlayBack',
                    key: 'SeePlayBack',
                    scopedSlots: { customRender: 'SeePlayBack' }
                },
                {
                    title: '设备及网络信息',
                    dataIndex: 'EquipmentAndNet',
                    key: 'EquipmentAndNet',
                    scopedSlots: { customRender: 'EquipmentAndNet' }
                },
                {
                    title: '课后跟踪',
                    dataIndex: 'remarks',
                    key: 'remarks',
                    scopedSlots: { customRender: 'remarks' }
                },
            ],
            shade:false,
            CurrPlayUrl:'',
            treeData: [],
            SchoolIds:[],
            SHOW_PARENT: 'SHOW_ALL',
            ShowStudentVisible:false,
            queryData:'',
            OnlineClassNetData:'',
            OnlineClassNetVisible:false,
            OnlineClassDeviceData:'',
            OnlineClassDeviceVisible:false,
            CurrShowStr:'',
            NetData:[],
            Num:'',
            Keyword:'',
            summary_data:'',
            OrderField:'ThedateNum',
            Order:'desc',
            RemarksVisible:false,
            Tags:undefined,//备注标签
            Type:undefined,//备注类型
            Content:undefined,//备注内容
            RemarksList:'',
            ClasstrackId:'',
            isRecord:undefined,
            RecordTags:undefined,
            MacId:undefined,
        }
    },
    created () {

        this.RangesConf = {
            '上上月': [
                moment().subtract(2,'months').startOf('month'),
                moment().subtract(2,'months').endOf('month')
            ],
            '上月':[
                moment().subtract(1,'months').startOf('month'),
                moment().subtract(1,'months').endOf('month')
            ],
            '本月':[
                moment().startOf("month"),
                moment().endOf('month')
            ]
        }

        this.StartDate = moment().week(moment().week()).startOf('week').format('YYYY-MM-DD');
        this.EndDate   = moment().format('YYYY-MM-DD');

        this._treeData();

        this._getData();
    },
    mounted(){
        
    },
    methods: {
        moment,
        onDateChange(v){
            if (v.length > 0) {
                this.StartDate = this.__moment__(v[0]).format('YYYY-MM-DD');
                this.EndDate = this.__moment__(v[1]).format('YYYY-MM-DD');
            }
        },
        handleSelectChange(v){
            this.SelectId = v;
        },
        reset(){
            this.SelectId = '';
            this.OrgIds = [];
            this.Keyword = '';
            this.isRecord = undefined;
            this.RecordTags = undefined;
            this.MacId = undefined;
        },
        _search(){
            this.currPage = 1;
            this._getData();
        },
        _getData(){
            this.$message.loading('拉取数据中...')
            this.$axios.post(8000101,{
                    LabelName:'OnlineClass|Teacher|index',
                    OrgIds:this.OrgIds,
                    StartDate:this.StartDate,
                    EndDate:this.EndDate,
                    Page:this.currPage,
                    Keyword:this.Keyword,
                    OrderField:this.OrderField,
                    Order:this.Order,
                    isRecord:this.isRecord,
                    RecordTags:this.RecordTags,
                    MacId:this.MacId
                },
                res => {
                    console.log(res)
                    if (res.data.code == 1) {
                        this.dataList = res.data.data.list;
                        this.DataCount = res.data.data.count;
                        this.summary_data = res.data.data.summary_data;
                    } else {
                        this.dataList = [];
                        this.DataCount = 0;
                        this._init_summary_data();

                    }
                    this.$message.destroy()
                }
            )
        },
        _init_summary_data(){
            this.summary_data = {
                Cost:0,
                ActualClassTime:0,
                ClassTime:0,
                absence_count:0,
                attendance_data:{
                    attendance:0,
                    late:0,
                    leave_early:0,
                    normal:0
                }
            };
        },
        _changePage(p){
            this.currPage = p;
            this. _getData();
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        _onCloseVideo(){
            this.shade = false;
            this.CurrPlayUrl = '';
            this.$refs.video.pause();
        },
        SeePlayBack(url){
            this.shade = true;
            this.CurrPlayUrl = url;
        },
        _treeData () {
          let self = this
          this.$axios.get(
            1090,
            {
              UserId: self.userinfo.uid
            },
            res => {
              self.treeData = res.data
            }
          )
        },
        onOrgChange(value, label, text){
            if (text.checked) {
                this.SchoolIds.push(text.triggerValue)
                } else {
                if (this.SchoolIds.length > 0) {
                  for (var i = 0; i < this.SchoolIds.length; i++) {
                    if (text.triggerValue == this.SchoolIds[i]) {
                      this.SchoolIds.splice(i, 1)
                    }
                  }
                }
            }

            this.OrgName = label
            this.OrgIds = value
            console.log(this.SchoolIds);
            console.log(this.OrgIds);
        },
        _StudentList(d,IsAll){
            if (d.StudentNum == 0) {
                return;
            }
            this.ShowStudentVisible = true;
            this.queryData = {
                ClasstrackId:d.ClasstrackId,
                CourseId:d.CourseId,
                StudentList:d.StudentList,
                UIDS:d.UIDS,
                IsAll:IsAll
            };
        },
        onCloseShowStudent(){
            this.ShowStudentVisible = false;
        },
        onCloseOnlineClassNet(){
            this.OnlineClassNetVisible = false;
        },
        _showOnlineClassNet(d){
            this.OnlineClassNetVisible = true;
            this.OnlineClassNetData = {
                CourseId:d.CourseId,
                UID:d.ClassinId
            };
        },
        onCloseOnlineClassDevice(){
            this.OnlineClassDeviceVisible = false;
        },
        _showOnlineClassDevice(d){
            this.OnlineClassDeviceVisible = true;
            this.OnlineClassDeviceData = {
                CourseId:d.CourseId,
                UID:d.ClassinId
            };
        },
        _showEchart(record,str,e){
            let that = this;

            that.CurrShowStr = str;

            that.Num = record.Num;

            if (e) { that._getNetCpuData(record,e); }
        },
        _init_echart_net(data,index,e){
            let that = this;
            setTimeout(function(){
                let myChart = echarts.init(document.getElementById('echarts-data-net-'+index),null,null);

                if (!e) {myChart.clear();return;}
                
                let option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {            // Use axis to trigger tooltip
                            type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
                        }
                    },
                    legend: {
                        data: ['很差', '较差', '正常', '较好']
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value'
                    },
                    yAxis: {
                        type: 'category',
                        data: data.yAxis
                    },
                    series: [
                        {
                            name: '很差',
                            type: 'bar',
                            stack: 'total',
                            label: {
                                show: true
                            },
                            data: data.series_data.net.poor,
                            itemStyle:{
                                normal:{
                                    color:'#f34336'
                                }
                            }
                        },
                        {
                            name: '较差',
                            type: 'bar',
                            stack: 'total',
                            label: {
                                show: true
                            },
                            data: data.series_data.net.bad,
                            itemStyle:{
                                normal:{
                                    color:'#f3b414'
                                }
                            }
                        },
                        {
                            name: '正常',
                            type: 'bar',
                            stack: 'total',
                            label: {
                                show: true
                            },
                            data: data.series_data.net.normal,
                            itemStyle:{
                                normal:{
                                    color:'#2ba76c'
                                }
                            }
                        },
                        {
                            name: '较好',
                            type: 'bar',
                            stack: 'total',
                            label: {
                                show: true
                            },
                            data: data.series_data.net.good,
                            itemStyle:{
                                normal:{
                                    color:'#30ba78'
                                }
                            }
                        }
                    ]
                };

                option && myChart.setOption(option);
            },500);
        },
        _init_echart_cpu(data,index,e){
            let that = this;
            setTimeout(function(){
                let myChart = echarts.init(document.getElementById('echarts-data-cpu-'+index),null,null);

                if (!e) {myChart.clear();return;}
                
                let option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {            // Use axis to trigger tooltip
                            type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
                        }
                    },
                    legend: {
                        data: ['低', '中', '高', '忙']
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value'
                    },
                    yAxis: {
                        type: 'category',
                        data: data.yAxis
                    },
                    series: [
                        {
                            name: '低',
                            type: 'bar',
                            stack: 'total',
                            label: {
                                show: true
                            },
                            data: data.series_data.cpu.cpuLow,
                            itemStyle:{
                                normal:{
                                    color:'#30ba78'
                                }
                            }
                        },
                        {
                            name: '中',
                            type: 'bar',
                            stack: 'total',
                            label: {
                                show: true
                            },
                            data: data.series_data.cpu.cpuMedium,
                            itemStyle:{
                                normal:{
                                    color:'#2ba76c'
                                }
                            }
                        },
                        {
                            name: '高',
                            type: 'bar',
                            stack: 'total',
                            label: {
                                show: true
                            },
                            data: data.series_data.cpu.cpuHigh,
                            itemStyle:{
                                normal:{
                                    color:'#f3b414'
                                }
                            }
                        },
                        {
                            name: '忙',
                            type: 'bar',
                            stack: 'total',
                            label: {
                                show: true
                            },
                            data: data.series_data.cpu.cpuBusy,
                            itemStyle:{
                                normal:{
                                    color:'#f34336'
                                }
                            }
                        }
                    ]
                };

                option && myChart.setOption(option);
            },500);
        },
        _getNetCpuData(record,e){
            let that = this;
            if (that.NetData[that.Num]) {
                if (that.CurrShowStr == 'net') {
                    that._init_echart_net(that.NetData[that.Num],that.Num,e);
                } else {
                    that._init_echart_cpu(that.NetData[that.Num],that.Num,e);
                }
            } else {
                this.$axios.post(8000101,{
                        LabelName:'OnlineClass|Teacher|net',
                        CourseId:record.CourseId,
                        UID:record.ClassinId
                    },
                    res => {
                        this.NetData = [];
                        if (res.data.code == 1) {
                            that.NetData[that.Num] = res.data.data.list;
                            if (that.CurrShowStr == 'net') {
                                that._init_echart_net(res.data.data.list,that.Num,e);
                            } else {
                                that._init_echart_cpu(res.data.data.list,that.Num,e);
                            }
                        } else {
                            console.log('获取不到数据')
                        }
                    }
                )
            }            
        },
        handleTableChange(pagination, filters, sorter){

          this.OrderField = sorter.field;

          switch (sorter.order) {
            case 'ascend':
              this.Order = 'asc';
              break;
            case 'descend':
              this.Order = 'desc';
              break;
          }

          this._getData();
        },
        onCloseRemarksVisible(){
            this.Type = undefined;
            this.Tags = undefined;
            this.Content = undefined;
            this.RemarksVisible = false;
            console.log(1111)
        },
        show_remarks(ClasstrackId){
            this.ClasstrackId = ClasstrackId;
            this.RemarksVisible = true;
            this.getRemarks(ClasstrackId);
        },
        onTypeHandleChange(v){
            this.Type = v;
        },
        onTagsHandleChange(v){
            this.Tags = v;
        },
        SaveRemarks(){
            if (!this.Type) {
                this.$message.error('未选择类型');
                return;
            }
            if (!this.Tags) {
                this.$message.error('未选择标签');
                return;
            }
            if (!this.Content) {
                this.$message.error('未填写内容');
                return;
            }

            this.$axios.post(8000102,{
                    LabelName:'OnlineClass|Teacher|add_remarks',
                    Content:this.Content,
                    Type:this.Type,
                    Tags:this.Tags,
                    ClasstrackId:this.ClasstrackId
                },
                res => {
                    if (res.data.code == 1) {
                        this.getRemarks(this.ClasstrackId);
                    } else {
                        this.$message.error(res.data.message);
                    }
                }
            )
        },
        getRemarks(ClasstrackId){
            this.$axios.post(8000101,{
                    LabelName:'OnlineClass|Teacher|get_remarks',
                    ClasstrackId:ClasstrackId
                },
                res => {
                    if (res.data.code == 1) {
                        this.RemarksList = res.data.data;
                    } else {
                        this.RemarksList = '';
                    }
                }
            )
        },
        JumpStudent(){
            this.$router.push({path: "/OnlineClassStudentList",query:{}});
        },
        
    }
}
</script>

<style lang="less" scoped>
.OnlineClassTeacher {
    width: 100%;
    min-height: 100%;
    background-size: 100%;
    position: relative;
    border-radius: 5px;
    background-color: #FFF;
    padding: 15px;
}

.table-list{
    margin-top: 30px;
    margin-left: 20px;
}

.search-item{
    margin-left: 19px;
    margin-top: 33px;
    display: inline-flex;
}

.shade{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .5;
    z-index: 99999;
}
.seevideo{
    position: absolute;
    top: 1%;
    left: 49%;
    transform: translate(-50%, 50%);
    z-index: 999999;
}

.stat-data{
    width: 80%;
    background-color: #eff6ff;
    margin-left: 20px;
    height:150px;
    padding:30px;
    margin-top: 20px;
}

.stat-item{
    width: 22%;
    display: -ms-flexbox;
    height: 90px;
    background-color: #ffffff;
    margin-right: 20px;
    display: inline-block;
}
.stat-text{
    margin: 25px;
}
.stat-num{
    font-weight: 600;
    font-size:18px;
}
.stat-text-title{
    font-weight: 300;
    font-size: 12px;
}
</style>
