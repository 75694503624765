<template>
  <div class="OnlineClassStudent">
    <!-- <div class="search-item"></div> -->
	<div class="table-list">
        <a-table :columns="columns" :data-source="dataList" :pagination="false">
            <!-- <div slot="EquipmentAndNet" slot-scope="text, record, index">
                <a href="#" style="color: #1890ff;" @click="_showOnlineClassDevice(record)">设备</a>&nbsp;&nbsp;
            </div> -->

            <div v-if="record.ActualClassTime > 0" slot="EquipmentAndNet" slot-scope="text, record, index">
                <a href="#" style="color: #1890ff;" @click="_showOnlineClassDevice(record)">设备</a>&nbsp;&nbsp;
                <a-popover trigger="hover" placement="left" @visibleChange="_showEchart(record,'net',$event)">
                  <template slot="content">
                    <div :id="'echarts-data-net-'+record.ClassinId" style="width:550px;height:350px"></div>
                  </template>
                  <a href="#" style="color: #1890ff;">网络</a>
                </a-popover>
                <!-- &nbsp;&nbsp;
                <a-popover  trigger="hover" placement="left" @visibleChange="_showEchart(record,'cpu',$event)">
                  <template slot="content">
                    <div :id="'echarts-data-cpu-'+index" style="width:550px;height:350px"></div>
                  </template>
                  <a href="#" style="color: #1890ff;">CPU</a>
                </a-popover> -->
            </div>
            <div v-else slot="EquipmentAndNet" slot-scope="text, record, index" style="text-align: center;">
                --
            </div>

            <div slot="SeePlayBack" slot-scope="text, record, index">
                <a href="#" v-if="text" @click="SeePlayBack(text)" style="color: #1890ff;">播放</a>
            </div>
            <div slot="StudentNum" slot-scope="text, record, index">
                <a href="#" @click="_StudentList(record)" style="color: #1890ff;">{{text}}</a>
            </div>
            <div slot="AttendanceNum" slot-scope="text, record, index">
                <a href="#" @click="_StudentList(record)" style="color: #1890ff;">{{text}}</a>
            </div>
        </a-table>
        <a-pagination v-model="currPage" v-if="DataCount > 10" style="margin-top: 18px;text-align: right;" @change="_changePage" :total="DataCount"  show-less-items />
    </div>

    <a-drawer title="网络信息" placement="right" width="1200" :closable="false" @close="onCloseOnlineClassNet":visible="OnlineClassNetVisible">
        <OnlineClassNet :OnlineClassNetData="OnlineClassNetData"></OnlineClassNet>
    </a-drawer>

    <a-drawer title="设备信息" placement="right" width="1200" :closable="false" @close="onCloseOnlineClassDevice":visible="OnlineClassDeviceVisible">
        <OnlineClassDevice :OnlineClassDeviceData="OnlineClassDeviceData"></OnlineClassDevice>
    </a-drawer>

  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import moment from 'moment'
import OnlineClassNet from 'pages/ReportCenter/OnlineClassNet'
import OnlineClassDevice from 'pages/ReportCenter/OnlineClassDevice'
import * as echarts from 'echarts'
export default {
	name: 'OnlineClassStudent',
    components: { OnlineClassNet, OnlineClassDevice, echarts },
    data () {
        return {
            userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
            dataList :[],
            RangesConf:{},
            SelectId:'',
            StartDate:undefined,
            EndDate:undefined,
            defaultDate:[moment().week(moment().week()).startOf('week'),moment()],
            currPage:1,
            DataCount:0,
            LabelName:'ClassSummary|Individual|',
            OrgIds:[],
            SelectDataList:[],
            func:'',
            columns:[
                {
                    title: '名称',
                    dataIndex: 'StudentName',
                    key: 'StudentName',
                },
                {
                    title: '所属中心',
                    dataIndex: 'SchoolName',
                    key: 'SchoolName',
                },
                {
                    title: '手机号',
                    dataIndex: 'Phone',
                    key: 'Phone',
                },
                {
                    title: '出勤',
                    dataIndex: 'Attendance',
                    key: 'Attendance',
                },
                {
                    title: '迟到',
                    dataIndex: 'Late',
                    key: 'Late',
                },
                {
                    title: '早退',
                    dataIndex: 'LeaveEarly',
                    key: 'LeaveEarly',
                },
                {
                    title: '上课时长(分)',
                    dataIndex: 'ActualClassTime',
                    key: 'ActualClassTime',
                },
                {
                    title: '设备及网络信息',
                    dataIndex: 'EquipmentAndNet',
                    key: 'EquipmentAndNet',
                    scopedSlots: { customRender: 'EquipmentAndNet' }
                },
            ],
            shade:false,
            CurrPlayUrl:'',
            treeData: [],
            SchoolIds:[],
            SHOW_PARENT: 'SHOW_ALL',
            OnlineClassNetData:'',
            OnlineClassNetVisible:false,
            OnlineClassDeviceData:'',
            OnlineClassDeviceVisible:false,
            _CurrShowStr:'',
            _NetData:[],
        }
    },
    props: ['queryData'],
    watch: {
        queryData: {
            immediate: true,
            handler (val) {
                this._NetData = [];
                this._CurrShowStr = '';
                this._getData(val);
            },
            deep: true
        }
    },
    created () {

        // this.RangesConf = {
        //     '上上月': [
        //         moment().subtract(2,'months').startOf('month'),
        //         moment().subtract(2,'months').endOf('month')
        //     ],
        //     '上月':[
        //         moment().subtract(1,'months').startOf('month'),
        //         moment().subtract(1,'months').endOf('month')
        //     ],
        //     '本月':[
        //         moment().startOf("month"),
        //         moment().endOf('month')
        //     ]
        // }

        // this.StartDate = moment().week(moment().week()).startOf('week').format('YYYY-MM-DD');
        // this.EndDate   = moment().format('YYYY-MM-DD');

        // this._treeData();

        // this._getData();
    },
    mounted(){
        
    },
    methods: {
        moment,
        onDateChange(v){
            if (v.length > 0) {
                this.StartDate = this.__moment__(v[0]).format('YYYY-MM-DD');
                this.EndDate = this.__moment__(v[1]).format('YYYY-MM-DD');
            }
        },
        handleSelectChange(v){
            this.SelectId = v;
        },
        reset(){
            this.SelectId = '';
            this.OrgIds = [];
        },
        _search(){
            this.currPage = 1;
            this._getData();
        },
        _getData(data = {}){
            this.$message.loading('拉取数据中...')
            this.$axios.post(8000101,{
                    LabelName:'OnlineClass|Teacher|student',
                    ClasstrackId:data.ClasstrackId,
                    CourseId:data.CourseId,
                    StudentList:data.StudentList,
                    UIDS:data.UIDS,
                    IsAll:data.IsAll
                },
                res => {
                    console.log(res)
                    if (res.data.code == 1) {
                        this.dataList = res.data.data.list;
                        this.DataCount = res.data.data.count;
                    } else {
                        this.dataList = [];
                        this.DataCount = 0;
                    }
                    this.$message.destroy()
                }
            )
        },
        _changePage(p){
            this.currPage = p;
            this. _getData();
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        _onCloseVideo(){
            this.shade = false;
            this.CurrPlayUrl = '';
            this.$refs.video.pause();
        },
        SeePlayBack(url){
            this.shade = true;
            this.CurrPlayUrl = url;
        },
        _treeData () {
          let self = this
          this.$axios.get(
            1090,
            {
              UserId: self.userinfo.uid
            },
            res => {
              self.treeData = res.data
            }
          )
        },
        onOrgChange(value, label, text){
            if (text.checked) {
                this.SchoolIds.push(text.triggerValue)
                } else {
                if (this.SchoolIds.length > 0) {
                  for (var i = 0; i < this.SchoolIds.length; i++) {
                    if (text.triggerValue == this.SchoolIds[i]) {
                      this.SchoolIds.splice(i, 1)
                    }
                  }
                }
            }

            this.OrgName = label
            this.OrgIds = value
            console.log(this.SchoolIds);
            console.log(this.OrgIds);
        },
        _StudentList(d){
            console.log(d);
        },
        onCloseOnlineClassNet(){
            this.OnlineClassNetVisible = false;
        },
        _showOnlineClassNet(d){
            console.log(d)
            this.OnlineClassNetVisible = true;
            this.OnlineClassNetData = {
                CourseId:d.CourseId,
                UID:d.ClassinId
            };
        },
        onCloseOnlineClassDevice(){
            this.OnlineClassDeviceVisible = false;
        },
        _showOnlineClassDevice(d){
            this.OnlineClassDeviceVisible = true;
            this.OnlineClassDeviceData = {
                CourseId:d.CourseId,
                UID:d.ClassinId
            };
        },
        _showEchart(record,str,e){
            let that = this;

            that.CurrShowStr = str;

            that.Num = record.ClassinId;

            if (e) { that._getNetCpuData(record,e); }
        },
        _init_echart_net(data,index,e){
            let that = this;
            setTimeout(function(){

                let myChart = echarts.init(document.getElementById('echarts-data-net-'+index),null,null);

                if (!e) {myChart.clear();return;}
                console.log(document.getElementById('echarts-data-net-'+index))
                let option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {            // Use axis to trigger tooltip
                            type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
                        }
                    },
                    legend: {
                        data: ['很差', '较差', '正常', '较好']
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value'
                    },
                    yAxis: {
                        type: 'category',
                        data: data.yAxis
                    },
                    series: [
                        {
                            name: '很差',
                            type: 'bar',
                            stack: 'total',
                            label: {
                                show: true
                            },
                            data: data.series_data.net.poor,
                            itemStyle:{
                                normal:{
                                    color:'#f34336'
                                }
                            }
                        },
                        {
                            name: '较差',
                            type: 'bar',
                            stack: 'total',
                            label: {
                                show: true
                            },
                            data: data.series_data.net.bad,
                            itemStyle:{
                                normal:{
                                    color:'#f3b414'
                                }
                            }
                        },
                        {
                            name: '正常',
                            type: 'bar',
                            stack: 'total',
                            label: {
                                show: true
                            },
                            data: data.series_data.net.normal,
                            itemStyle:{
                                normal:{
                                    color:'#2ba76c'
                                }
                            }
                        },
                        {
                            name: '较好',
                            type: 'bar',
                            stack: 'total',
                            label: {
                                show: true
                            },
                            data: data.series_data.net.good,
                            itemStyle:{
                                normal:{
                                    color:'#30ba78'
                                }
                            }
                        }
                    ]
                };

                option && myChart.setOption(option);
            },500);
        },
        _getNetCpuData(record,e){
            let that = this;
            this.$axios.post(8000101,{
                    LabelName:'OnlineClass|Teacher|net',
                    CourseId:record.CourseId,
                    UID:record.ClassinId
                },
                res => {
                    this._NetData = [];
                    if (res.data.code == 1) {
                        that._NetData[that.Num] = res.data.data.list;
                        that._init_echart_net(res.data.data.list,that.Num,e);
                    } else {
                        console.log('获取不到数据')
                    }
                }
            )            
        },
    }
}
</script>

<style lang="less" scoped>
.OnlineClassStudent {
    width: 100%;
    min-height: 100%;
    background-size: 100%;
    position: relative;
}


.search-item{
    margin-left: 19px;
    margin-top: 33px;
    display: inline-flex;
}

.shade{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .5;
    z-index: 99999;
}
.seevideo{
    position: absolute;
    top: 1%;
    left: 49%;
    transform: translate(-50%, 50%);
    z-index: 999999;
}
</style>
